import React from 'react'
import OrderItem from './OrderItem'
import 'twin.macro'
import { orderItemsAtom } from '../../states/selectedProduct'
import { useAtom } from 'jotai'
import { map } from 'lodash'
import { sortOrderItemsForShowup } from '../../utils/utils'
const OrderItems = () => {
  const [orderItems] = useAtom(orderItemsAtom)
  const sortedOrderItems = sortOrderItemsForShowup(orderItems)
  return (
    <div tw="h-72 md:h-96 overflow-y-scroll">
      {map(sortedOrderItems, (orderItem, index) => (
        <OrderItem orderItem={orderItem} key={index} />
      ))}
    </div>
  )
}

export default OrderItems
