import tw from 'twin.macro'
import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useForm, FormProvider, useFormContext } from 'react-hook-form'
import { Divider, Icon } from 'semantic-ui-react'
import _, { forEach, uniq, compact, intersection, head, maxBy } from 'lodash'
import { v4 as uuid } from 'uuid'
import { useAtom, useAtomValue } from 'jotai'
import Cookies from 'js-cookie'
import { navigate } from 'gatsby'
import { RESET } from 'jotai/utils'
import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'

import Layout from '../components/Layouts/cakeDefault'
import TailwindLayout from '../components/Layouts/TailwindLayout'
import { cakeItemVariants, NEED_CSKH_CHECK } from '../utils/constants'
import {
  currencyFormatter,
  getTotalOriginalValue,
  getTotalDiscount,
  getTotalOrderValue,
  getFarthestAvailableDate,
  getCakeTypesOrderInfo,
  getVoucherDiscout,
  getFullAddress,
  fetchKitchenOrderCondition,
  convertDistanceToNumber,
} from '../utils/utils'
import {
  validateFeaturedSkusOnEventDays,
  inPeak20t10,
  valentineAvailable,
  checkValentineInStoryOnly,
  getLabelCakeEventDayBySelectedDate,
  checkInPeakShipping,
  offComboHoliday30t4,
  isBetweenEventDays,
  fatherDates,
  mooncakeDates,
} from '../utils/eventDays'
import {
  getMooncakePreOrderDayNum,
  getMooncakeOrderTime,
} from '../utils/ordersTime'
import { getShippingFee, shipPolicy } from '../utils/shipping'
import {
  receiveHours,
  today,
  tomorrow,
  availableSpecialDay,
  checkInSpecialDaysOff,
  getPrepareTimeDisplay,
  getSocolaOrderTimeByOutlet,
  getAdjustedCurrentHour,
} from '../utils/receiveHours'
import { orderItemsAtom } from '../states/selectedProduct'
import { selectedVoucherAtom } from '../states/selectedVoucher'
import { selectedDateAtom, lastPickedHourAtom } from '../states/selectedDate'
import {
  disablePickUpStoreAtom,
  pickUpStoreAtom,
  selectedStoreAtom,
} from '../states/selectedAddress'
import { modalRemindVisibleAtom } from '../states/modal.state'
import { orderFormDataAtom } from '../states/orderFormData.state'
import tracker from '../utils/tracker'
import { errorToast, infoToast } from '../utils/toastify'

import {
  OrderItems,
  ModeOfPayment,
  SelectVoucher,
} from '../components/CheckoutPage'

import {
  ReceiveDateForm,
  AddressForm,
  CustomerNote,
  CardAndBillForm,
  StoreForm,
  Cart,
} from '../components/DatHangPage'
import { Input } from '../components/Base/Forms'
import useAddress from '../hooks/useAddress'
import { onlineOrderValidationSchema } from '../utils/schema'
import { CheckoutHeading2, CheckoutDescStyle } from '../components/Base'
import {
  birthdayCakeSkus,
  fatherDaySkus,
  motherDaySkus,
} from '../utils/products'
import { boxAccessories } from '../utils/accessories'
import { WarnNoteStyle } from '../components/DatHangPage/ReceiverDateForm'
import useReceiveHours from '../hooks/useReceiveHours'
import { retailMooncakeSkus } from '../utils/mooncakes'
import useOrderCondition from '../hooks/useOrderCondition'
import { kitchenConditionsMapAtom } from '../states/kitchenCondition.state'

const maxMoonDays = ['2023-09-24', '2023-09-25']

export default function Checkout() {
  const methods = useForm({
    resolver: yupResolver(onlineOrderValidationSchema),
  })

  // Dữ liệu tùy chỉnh đặt hàng các bếp
  // todo: consider move to useOrderCondition
  const [kitchenConditionsMap, setKitchenConditionsMapAtom] = useAtom(
    kitchenConditionsMapAtom
  )
  useEffect(() => {
    async function fetchKitchenCondition() {
      const kitchenCondition = await fetchKitchenOrderCondition()
      setKitchenConditionsMapAtom(kitchenCondition)
    }
    fetchKitchenCondition()
  }, [])
  useOrderCondition()

  const [uniqueOrderId, setUniqueOrderId] = useState()
  useEffect(() => {
    setUniqueOrderId(uuid())
  }, [])

  const [currentHour, setCurrentHour] = useState(getAdjustedCurrentHour())

  useEffect(() => {
    // console.log('set interval')
    const intervalId = setInterval(() => {
      // console.log('interval', getAdjustedCurrentHour())
      setCurrentHour(getAdjustedCurrentHour())
    }, 60000) // Update every half minute

    return () => clearInterval(intervalId)
  })

  const {
    longestPrepareTime,
    preOrderDate,
    farthestAvailableDate,
    handleSetValidReceiveHours,
    validHours,
  } = useReceiveHours()

  const [, setOrderFormData] = useAtom(orderFormDataAtom)
  const [orderItems, setOrderItems] = useAtom(orderItemsAtom)
  const [pickUpStore, setPickUpStore] = useAtom(pickUpStoreAtom)
  const disablePickUpStore = useAtomValue(disablePickUpStoreAtom)
  const [loading, setLoading] = useState(false)
  const [totalOriginalValue, setTotalOriginalValue] = useState(0)
  const [totalDiscount, setTotalDiscount] = useState(0)
  // tương đương onlOrder.orderValue: đã tính các khoản giảm trừ như discount và voucher
  const [totalWithoutShip, setTotalWithoutShip] = useState(0)
  const [voucher] = useAtom(selectedVoucherAtom)
  const [shippingFee, setShippingFee] = useState(0)

  const dinosClickId = Cookies.get('dinos_click_id')
  const tiktokClickId = Cookies.get('tiktok_click_id')
  const facebookClickId = Cookies.get('facebook_click_id')
  const googleClickId = Cookies.get('google_click_id')
  const psid = Cookies.get('psid')
  const zaloClickId = Cookies.get('zalo_click_id')

  /** start calculating date */
  // time
  const [lastPickedHour, setLastPickedHour] = useAtom(lastPickedHourAtom)
  const getProperHourForSetSelected = useCallback(
    (hours) => {
      const hourLabels = hours.map((hour) => hour.label)
      if (lastPickedHour && _.includes(hourLabels, lastPickedHour.label)) {
        return lastPickedHour
      }
      if (lastPickedHour) {
        setLastPickedHour(null)
      }
      return head(hours)
    },
    [lastPickedHour] // eslint-disable-line react-hooks/exhaustive-deps
  )
  const [selectedHour, setSelectedHour] = useState(
    getProperHourForSetSelected(validHours)
  )
  const [, setSelectedEndHour] = useState(head(validHours))

  // products
  const {
    mooncakeCount,
    minMooncakeReceiveDate,
    birthdayCakeCount,
    valentineCount,
    // tetCakeCandyCount,
  } = useMemo(() => {
    const cakeTypesOrderInfo = getCakeTypesOrderInfo({
      orderItems,
    })
    const mooncakePreOrderDayNum = getMooncakePreOrderDayNum({
      mooncakeCount: cakeTypesOrderInfo.mooncakeCount,
    })
    const { addMoonDay } = getMooncakeOrderTime({ currentHour })

    const normalMooncakeReceiveDate = dayjs(today)
      .add(mooncakePreOrderDayNum + addMoonDay, 'd')
      .format('YYYY-MM-DD')

    let minMooncakeReceiveDate = normalMooncakeReceiveDate
    if (maxMoonDays.includes(normalMooncakeReceiveDate)) {
      minMooncakeReceiveDate = '2023-09-26'
    }

    return {
      ...cakeTypesOrderInfo,
      minMooncakeReceiveDate,
    }
  }, [orderItems, currentHour])

  const [selectedDate, setSelectedDate] = useAtom(selectedDateAtom)
  useEffect(() => {
    setSelectedDate(mooncakeCount ? minMooncakeReceiveDate : today)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  // card and bill
  const [includeBill, setIncludeBill] = useState(true)
  const [requestCard, setRequestCard] = useState(false)
  const [requestCardContent, setRequestCardContent] = useState('')

  // address
  const {
    handleCheckDistance,
    distance,
    distanceError,
    setDistance,
    setDistanceError,
    shippingAreas,
    districts,
    street,
    selectedDistrict,
    selectedWard,
    wardsOfDistrict,
    selectedShippingObj,
    setStreet,
    setSelectedWard,
    setSelectedDistrict,
    setSelectedShippingObj,
  } = useAddress()
  const [selectedStore, setSelectedStore] = useAtom(selectedStoreAtom)
  const togglePickUpStore = () => {
    if (pickUpStore) {
      setSelectedStore('')
    } else {
      setStreet('')
      setSelectedWard('')
      setSelectedDistrict('')
      setSelectedShippingObj({})
    }
    setPickUpStore(!pickUpStore)
  }
  useEffect(() => {
    setDistance('')
    setDistanceError('')
  }, [])

  // tính giá trị đơn hàng
  useEffect(() => {
    setTotalOriginalValue(getTotalOriginalValue({ orderItems }))
    setTotalDiscount(getTotalDiscount({ orderItems }))
    setTotalWithoutShip(getTotalOrderValue({ orderItems, voucher }))

    const shipObj = getShippingFee({
      totalValue: totalOriginalValue - totalDiscount,
      orderItems,
      selectedDate,
      pickUpStore,
      selectedHour,
      selectedStore,
    })
    setShippingFee(shipObj.shipping)
  }, [
    voucher,
    orderItems,
    totalOriginalValue,
    totalDiscount,
    selectedDate,
    pickUpStore,
    selectedHour,
    selectedStore,
  ])

  // --- START calculate receive date & time
  // fast delivery
  const orderPrepareTimes = _.uniq(_.map(orderItems, 'prepareTime')) || []
  const hasFastDelivery = orderPrepareTimes.includes(0.5)
  const rejectFastDelivery = hasFastDelivery && orderPrepareTimes.length > 1

  // tính giờ nhận hàng available
  const [isHoursCalculated, setIsHoursCalculated] = useState(false)

  const valentineOutletOrderTime = useMemo(
    () =>
      getSocolaOrderTimeByOutlet({
        outletCode: pickUpStore
          ? selectedStore
          : selectedShippingObj.valentineStore,
      }) || {},
    [pickUpStore, selectedStore, selectedShippingObj.valentineStore]
  )
  useEffect(() => {
    handleSetValidReceiveHours({
      selectedShippingObj,
      valentineOutletOrderTime,
      currentHour,
    })
    // for min date
    setIsHoursCalculated(true)
  }, [
    selectedDate,
    orderItems,
    longestPrepareTime,
    pickUpStore,
    selectedStore,
    selectedShippingObj,
    valentineOutletOrderTime,
    currentHour,
  ]) // eslint-disable-line react-hooks/exhaustive-deps

  // tính ngày + giờ nhận hàng
  const [inSpecialDaysOff, setInSpecialDaysOff] = useState(false)
  useEffect(() => {
    setInSpecialDaysOff(
      checkInSpecialDaysOff({
        selectedDate,
        valentineCount,
        valentineOutletOrderTime,
      })
    )
  }, [selectedDate, valentineCount, valentineOutletOrderTime])

  const [countReceiveDateCalculation, setCountReceiveDateCalculation] =
    useState(0)

  useEffect(() => {
    // console.log('checkout validHours', validHours)
    // console.log('checkout', checkInSpecialDaysOff({selectedDate}))
    if (!isHoursCalculated) return
    const handleCountReceiveDateCalculation = () => {
      if (!selectedDate || countReceiveDateCalculation > 2) return
      setCountReceiveDateCalculation((preValue) => preValue + 1)
    }
    if (mooncakeCount) {
      console.log(`----- checkout mooncake`)
      if (
        dayjs(selectedDate).isAfter(dayjs('2023-09-25')) &&
        dayjs(today).isBefore(dayjs('2023-09-25'))
      ) {
        setSelectedHour(getProperHourForSetSelected(receiveHours))
      } else {
        setSelectedHour(getProperHourForSetSelected(validHours))
      }
      handleCountReceiveDateCalculation()
    } else {
      let finalAvailDate
      if (farthestAvailableDate && preOrderDate) {
        // nếu ngày avail sau ngày preorder thì lấy avail, không thì lấy pre
        finalAvailDate = dayjs(farthestAvailableDate).isAfter(
          dayjs(preOrderDate)
        )
          ? farthestAvailableDate
          : preOrderDate
      } else if (preOrderDate) {
        finalAvailDate = preOrderDate
      } else if (farthestAvailableDate) {
        finalAvailDate = farthestAvailableDate
      }

      if (finalAvailDate && validHours.length !== 0) {
        if (dayjs(selectedDate).isSameOrBefore(dayjs(finalAvailDate))) {
          console.log(`----- checkout final preOrderDate ${finalAvailDate}`)
          setSelectedDate(finalAvailDate)
          setSelectedHour(getProperHourForSetSelected(validHours))
          handleCountReceiveDateCalculation()
          return
        }
        setSelectedHour(getProperHourForSetSelected(receiveHours))
        handleCountReceiveDateCalculation()
        return
      }
      if (validHours.length === 0) {
        // ko còn slot đặt trong ngày
        if (inSpecialDaysOff) {
          if (valentineAvailable && valentineCount) {
            console.log(
              `----- checkout valentine day: ${valentineOutletOrderTime.openSocolaDate}`
            )
            setSelectedDate(valentineOutletOrderTime.openSocolaDate)
          } else {
            console.log(`----- checkout special day: ${availableSpecialDay}`)
            setSelectedDate(availableSpecialDay)
          }
          setSelectedHour(getProperHourForSetSelected(receiveHours))
          handleCountReceiveDateCalculation()
          return
        }
        console.log('----- checkout tmr', tomorrow)
        setSelectedDate(tomorrow)
        setSelectedHour(getProperHourForSetSelected(receiveHours))
        handleCountReceiveDateCalculation()
        return
      }
      console.log('----- checkout today')
      setSelectedHour(getProperHourForSetSelected(validHours))
      handleCountReceiveDateCalculation()
    }
  }, [
    validHours,
    inSpecialDaysOff,
    isHoursCalculated,
    countReceiveDateCalculation,
    mooncakeCount,
    selectedDate,
    farthestAvailableDate,
    preOrderDate,
  ]) // eslint-disable-line react-hooks/exhaustive-deps

  const [minReceiveDate, setMinReceiveDate] = useState(today)
  useEffect(() => {
    if (!countReceiveDateCalculation || countReceiveDateCalculation > 2) return
    // Chỉ tính 1 lần duy nhất: sau khi đã có receiveDate lần đầu tiên
    // (ban đầu là vậy sau dùng atom thì phải tăng lên 2 thì ok, chưa biết tại sao :<)
    let minDate = today
    if (mooncakeCount) {
      minDate = minMooncakeReceiveDate
    } else {
      if (selectedDate === availableSpecialDay) {
        minDate = availableSpecialDay
      } else if (selectedDate === farthestAvailableDate) {
        minDate = farthestAvailableDate
      } else if (selectedDate === preOrderDate) {
        minDate = preOrderDate
      } else if (selectedDate !== today) {
        minDate = tomorrow
      }
    }
    setMinReceiveDate(minDate)
  }, [
    countReceiveDateCalculation,
    selectedDate,
    mooncakeCount,
    minMooncakeReceiveDate,
  ]) // eslint-disable-line react-hooks/exhaustive-deps
  // --- END calculate receive date & time
  const [, setRemindModalVisible] = useAtom(modalRemindVisibleAtom)
  const checkEmptyCakeText = () => {
    // todo: test lại sau khi sửa orderItems logic uid
    const cakeItems = _.filter(orderItems, (item) =>
      birthdayCakeSkus.includes(item.sku)
    )
    return _.some(cakeItems, (item) => {
      const textInCake =
        _.find(_.get(item, 'variants', []), {
          type: cakeItemVariants.TEXT_IN_CAKE,
        }) || {}
      const textInBase =
        _.find(_.get(item, 'variants', []), {
          type: cakeItemVariants.TEXT_IN_BASE,
        }) || {}

      return (
        (!!item.isTextInCake && !textInCake.description) ||
        (!(item.isTextInBase === false) && !textInBase.description)
      )
    })
  }
  const preprocessOnSubmit = async (data) => {
    // đoạn validate text limit này tách ra hàm riêng nhìn đỡ dài
    const limitTextItems = _.filter(orderItems, (item) => {
      if (item.textCakeMaxChar) {
        const textInCake = _.find(
          _.get(item, 'variants', []),
          (variant) => variant.type === cakeItemVariants.TEXT_IN_CAKE
        )
        return textInCake.description.length > item.textCakeMaxChar
      }
      if (item.textBaseMaxChar) {
        const textInBase = _.find(
          _.get(item, 'variants', []),
          (variant) => variant.type === cakeItemVariants.TEXT_IN_BASE
        )
        return textInBase.description.length > item.textBaseMaxChar
      }
    })
    if (_.get(limitTextItems, 'length')) {
      return errorToast(
        `Các bánh sau có chữ viết vượt quá giới hạn\n${limitTextItems
          .map((item) => item.description)
          .join('<br/>')}`
      )
    }
    if (
      _.some(orderItems, (item) => item.id === 'mousse-sua-chua-vai') &&
      dayjs(selectedDate).isAfter(dayjs('2024-06-12').endOf('day'))
    ) {
      return errorToast(
        'Bánh mousse sữa chua vải nhận đơn đến hết 2024-06-12. Vui lòng bỏ khỏi giỏ hàng và tham khảo các mẫu bánh vải mới của Savor ạ.'
      )
    }
    // if (mooncakeCount > 0 && mooncakeCount < 8) {
    //   return errorToast(
    //     'Với bánh trung thu, hiện Savor chỉ nhận đơn từ 8 bánh trở lên.'
    //   )
    // }
    // if (valentineCount) {
    //   if (!valentineAvailable) {
    //     errorToast(
    //       'Tạm ngưng nhận đơn Socola mới. Vui lòng liên hệ qua fanpage hoặc hotline để được hỗ trợ thêm.'
    //     )
    //     return
    //   }
    //   if (birthdayCakeCount + mooncakeCount + tetCakeCandyCount > 0) {
    //     errorToast('Vui lòng đặt đơn riê`ng cho Socola.')
    //     return
    //   }
    // }
    const fetchedDistanceData = await handleCheckDistance()
    if (convertDistanceToNumber(fetchedDistanceData.distance) > 6) {
      const maxRootTime = _.get(
        maxBy(orderItems, 'rootPrepareTime'),
        'rootPrepareTime'
      )
      if (longestPrepareTime < maxRootTime) {
        return infoToast(
          'Khoảng cách giao quá 6km. Đã tự động cập nhật lại giờ giao bánh. Vui lòng kiểm tra lại đơn hàng và xác nhận lần nữa.'
        )
      }
    }
    setOrderFormData(data)
    if (birthdayCakeCount && checkEmptyCakeText()) {
      setRemindModalVisible(true)
    } else {
      setRemindModalVisible(false)
      onSubmit(data)
    }
  }

  useEffect(() => {
    if (
      !pickUpStore &&
      valentineCount &&
      checkValentineInStoryOnly(selectedDate)
    ) {
      setPickUpStore(true)
      setStreet('')
      setSelectedWard('')
      setSelectedDistrict('')
      setSelectedShippingObj({})
    }
  }, [valentineCount, selectedDate]) // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = (data) => {
    setRemindModalVisible(false)
    if (valentineCount && pickUpStore && !selectedStore) {
      errorToast('Chưa chọn cơ sở')
      return
    }
    const deliveryStartHour = selectedHour.start
    let deliveryEndHour = selectedHour.start + 1
    if (checkInPeakShipping({ selectedDate, selectedHour, dayLabel: '8t3' })) {
      deliveryEndHour = selectedHour.start + 2
    }
    if (checkInPeakShipping({ selectedDate, dayLabel: 'quoc_tang' })) {
      deliveryEndHour = selectedHour.start + 2
    }
    // const deliveryEndHour = compareWithEventDay({
    //   dayLabel: '8t3',
    //   checkingDate: selectedDate,
    // })
    //   ? selectedEndHour.start
    //   : selectedHour.start + 1
    const deliveryStartMinute = selectedHour.minute
    const deliveryEndMinute = selectedHour.minute
    // const deliveryEndMinute = compareWithEventDay({
    //   dayLabel: '8t3',
    //   checkingDate: selectedDate,
    // })
    //   ? selectedEndHour.minute
    //   : selectedHour.minute
    const {
      paymentMethod,
      orderCustomerInfo: name,
      orderPhoneNumber: cellphone,
      receiverInfo: receiverName,
      receiverPhoneNumber: receiverCellphone,
      orderNote,
    } = data
    let { receiveDate } = data
    if (receiveDate !== selectedDate) {
      receiveDate = selectedDate
    }
    const { city, district, ward, address } = getFullAddress({
      shippingAreas,
      street,
      selectedDistrict,
      selectedWard,
    })

    const orderSkus = _.uniq(_.map(orderItems, 'sku'))

    // --- preprocess items
    // todo: test lại sau khi sửa orderItems logic uid
    const micaBoxSkus = _.map(boxAccessories, 'sku')
    let preProcesseOrderItems = _.reject(orderItems, (item) =>
      _.includes(micaBoxSkus, item.sku)
    )
    const micaOrderItems = _.filter(orderItems, (item) =>
      _.includes(micaBoxSkus, item.sku)
    )
    let processedOrderItems = []
    if (_.get(micaOrderItems, 'length')) {
      // tính tổng số hộp mica trong giỏ
      const micaSumMap = _.reduce(
        micaOrderItems,
        (result, item) => {
          result[item.cakeSizeName] = {
            total: (_.get(result[item.sku], 'total') || 0) + item.quantity,
            item,
          }
          return result
        },
        {}
      )
      const remainWithoutMicaItems = []
      // xử lý ghép bánh và mica
      _.forEach(preProcesseOrderItems, (item) => {
        const matchSumMica =
          item.sizeName && !item.includedMica ? micaSumMap[item.sizeName] : {}
        let matchMicaItem
        if (matchSumMica && matchSumMica.total > 0) {
          const matchQty = Math.min(item.quantity, matchSumMica.total)
          micaSumMap[item.sizeName].total -= matchQty
          if (micaSumMap[item.sizeName].total <= 0) {
            delete micaSumMap[item.sizeName]
          }
          // xử lý parent
          const originQty = item.quantity
          item.quantity = matchQty
          const remainQty = originQty - matchQty
          if (remainQty) {
            remainWithoutMicaItems.push({ ...item, quantity: remainQty })
          }
          item.uid = item.uid || dayjs().valueOf()
          // xử lý child
          matchMicaItem = { ...matchSumMica.item }
          matchMicaItem.parentUid = item.uid
          matchMicaItem.quantity = matchQty
        }
        processedOrderItems.push(item)
        if (matchMicaItem && !_.isEmpty(matchMicaItem)) {
          // áp dụng chung kể cả ko có mica nên mới xét
          processedOrderItems.push(matchMicaItem)
        }
      })
      // thêm mã còn dư
      if (remainWithoutMicaItems.length) {
        processedOrderItems.push(...remainWithoutMicaItems)
      }
      if (!_.isEmpty(micaSumMap)) {
        const remainMicas = _.map(_.values(micaSumMap), 'item')
        processedOrderItems.push(...remainMicas)
      }
    }

    const cleanOrderItems = _.reduce(
      processedOrderItems.length ? processedOrderItems : orderItems,
      (result, item) => {
        if (_.get(item, 'variants.length')) {
          item.variants = _.reject(item.variants, { description: '' })
        }
        result.push(item)
        return result
      },
      []
    )
    // Xử lý auto add phụ kiện tặng
    // todo: tách ra file hàm xử lý riêng cho đỡ dài
    if (inPeak20t10({ selectedDate })) {
      cleanOrderItems.push({
        sku: 'V1816',
        description: 'Thiệp 20/10',
        originalPrice: 0,
        soldPrice: 0,
        quantity: 1,
      })
    }
    // mother day gift
    if (
      intersection(motherDaySkus, orderSkus).length > 0 &&
      isBetweenEventDays({
        selectedDate,
        startEvent: '2024-05-09',
        endEvent: '2024-05-12',
      })
    ) {
      const totalValue = getTotalOriginalValue({ orderItems })
      const finalGiftNum = _.reduce(
        cleanOrderItems,
        (total, item) => {
          if (!motherDaySkus.includes(item.sku)) {
            return total
          }
          total += item.quantity
          return total
        },
        0
      )

      if (finalGiftNum > 0) {
        cleanOrderItems.push({
          sku: 'V1457',
          description: "Que cắm Happy Mother's Day (chiếc)",
          originalPrice: 6000,
          soldPrice: 0,
          quantity: finalGiftNum,
        })
      }
      if (totalValue >= 300000) {
        cleanOrderItems.push({
          sku: 'V8474',
          description: 'Hộp mica hoa hồng một bông màu đỏ (hộp)',
          originalPrice: 30000,
          soldPrice: 0,
          quantity: 1,
        })
      }
    }
    // father day gift
    if (
      intersection(fatherDaySkus, orderSkus).length > 0 &&
      isBetweenEventDays({
        selectedDate,
        startEvent: fatherDates.start,
        endEvent: fatherDates.end,
      })
    ) {
      const finalGiftNum = _.reduce(
        cleanOrderItems,
        (total, item) => {
          if (!fatherDaySkus.includes(item.sku)) {
            return total
          }
          total += item.quantity
          return total
        },
        0
      )

      if (finalGiftNum > 0) {
        cleanOrderItems.push({
          sku: 'V8728',
          description: 'Que cắm Love you papa (chiếc)',
          originalPrice: 2000,
          soldPrice: 0,
          quantity: finalGiftNum,
        })
      }
    }
    // mooncake gift
    if (
      intersection(retailMooncakeSkus, orderSkus).length > 0 &&
      isBetweenEventDays({
        selectedDate,
        startEvent: mooncakeDates.start,
        endEvent: mooncakeDates.end,
      })
    ) {
      const finalGiftNum = _.reduce(
        cleanOrderItems,
        (total, item) => {
          if (!retailMooncakeSkus.includes(item.sku)) {
            return total
          }
          total += item.quantity
          return total
        },
        0
      )

      if (finalGiftNum > 0) {
        cleanOrderItems.push({
          sku: 'V9007',
          description: 'Hộp con bánh trung thu 125g Kim Long Vọng Nguyệt',
          originalPrice: 0,
          soldPrice: 0,
          quantity: finalGiftNum,
        })
      }
    }

    const order = {
      savorWebOrderId: uniqueOrderId,
      payment: {
        method: paymentMethod || 'cod',
      },
      receiveDate,
      deliveryStartHour,
      deliveryEndHour,
      deliveryStartMinute,
      deliveryEndMinute,
      savorWebItems: cleanOrderItems,
      includeBill,
      requestCard,
      requestCardContent,
      orderCustomer: {
        name,
        cellphone,
      },
      receiverCustomer: {
        name: receiverName,
        cellphone: receiverCellphone,
        address,
        street,
        ward,
        district,
        city,
      },
      distance,
      distanceError,
      selectedShippingObj,
      dinosClickId,
      tiktokClickId,
      facebookClickId,
      googleClickId,
      zaloClickId,
      orderNote,
      voucher: voucher ? voucher._id : undefined,
      storeCode: selectedStore,
      shipping: {
        provider: pickUpStore ? 'Giao tại cửa hàng' : 'Lalamove',
      },
      localPickupMethod: pickUpStore ? 'Giao tại cửa hàng' : 'Ship',
      pageLastModified: _.get(document, 'lastModified'),
      userAgent: navigator.userAgent,
    }
    order.kitchenConditions = kitchenConditionsMap
    if (psid) {
      order.psid = psid
    }
    const cartNotes = JSON.parse(localStorage.getItem('cartNotes'))
    if (cartNotes) {
      order.cartNotes = cartNotes
    }
    order.created = new Date()
    setOnlineOrder({ order })
  }
  // dữ liệu cần gửi sang bên ww
  const setOnlineOrder = async ({ order }) => {
    const orderFailText = 'Có lỗi khi tạo đơn hàng!'
    setLoading(true)
    try {
      const url = `${process.env.GATSBY_API_URL}/create-cake-order`
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ order }),
      })
      const resJson = await response.json()
      const { onlineOrderId, totalOrderValue, offOrder, createdTs } = resJson

      if (onlineOrderId) {
        setOrderItems(RESET)
        setLastPickedHour(null)
        Cookies.remove('psid')
        localStorage.removeItem('cartNotes')
        tracker.purchase({ value: totalWithoutShip })
        let thankLink = `/thank-you?payment=${_.get(
          order,
          'payment.method'
        )}&onlineOrderId=${onlineOrderId}&amount=${totalOrderValue}&createdTs=${createdTs}&offOrder=${offOrder}`
        if (mooncakeCount) {
          thankLink += `&mooncakeCount=${mooncakeCount}`
        }
        navigate(thankLink)
      } else {
        alert(`${orderFailText}\nError: ${resJson.message}`)
      }
    } catch (err) {
      console.log('Lỗi tạo đơn bánh', err)
      alert(`${orderFailText}\nChi tiết: ${err}`)
    } finally {
      setLoading(false)
    }
  }
  const [isValidSpecialEventOrder, setIsValidSpecialEventOrder] = useState(true)
  const cakeEventDayLabel = useMemo(() => {
    return getLabelCakeEventDayBySelectedDate(selectedDate) || {}
  }, [selectedDate])

  useEffect(() => {
    const isValidOrder = validateFeaturedSkusOnEventDays({
      dayLabel: cakeEventDayLabel,
      selectedDate,
      orderItems,
    })
    setIsValidSpecialEventOrder(isValidOrder)
    if (!isValidOrder) {
      errorToast(validOrderPropsMap[cakeEventDayLabel].invalidOrderError)
    }
  }, [selectedDate, orderItems, cakeEventDayLabel])

  return (
    <TailwindLayout>
      <Layout endPoint={'checkout'} title={'Savor Cake | Checkout - Đặt hàng'}>
        <Cart handleSubmit={onSubmit} />
        <FormProvider {...methods}>
          {!_.isEmpty(orderItems) ? (
            <form onSubmit={methods.handleSubmit(preprocessOnSubmit)}>
              <div tw="max-w-6xl mx-auto py-8 px-2 sm:px-4">
                <div tw="relative">
                  <div
                    tw="text-2xl md:text-4xl text-savor-cake-primary-green-avocado-0 font-bold
                    w-full text-right sm:text-center
                    pb-4 pr-4 sm:pr-0
                    relative font-savor-cake-primary-lexend
                    border-b-1 border-b-savor-cake-primary-green-avocado-0 mb-4
                    "
                  >
                    Xác nhận đơn hàng
                  </div>
                </div>
                <div tw="grid grid-cols-1 grid-rows-none mx-2 sm:(grid-cols-2 mx-0) sm:grid-rows-1">
                  <div tw="px-2 py-3">
                    <CheckoutHeading2>Thông tin người đặt</CheckoutHeading2>
                    <OrderCustomerForm />
                    <CheckoutHeading2>Thông tin người nhận</CheckoutHeading2>
                    <ReceiverCustomerForm />
                    <CheckoutHeading2>Địa chỉ nhận hàng</CheckoutHeading2>
                    {offComboHoliday30t4(selectedDate) ? (
                      <p css={WarnNoteStyle}>
                        Quý khách lưu ý: Savor xin phép sẽ không nhận đơn Lấy
                        tại cửa hàng vào ngày 18/4 và từ 29/4-1/5; chỉ ship trực
                        tiếp đến địa chỉ khách ạ.
                      </p>
                    ) : null}
                    <CustomCheckBox
                      handleSetValue={togglePickUpStore}
                      checkValue={pickUpStore}
                      checkContent={'Lấy tại cửa hàng'}
                      disabled={disablePickUpStore}
                    />
                    {pickUpStore ? (
                      <StoreForm />
                    ) : (
                      <AddressForm
                        districts={districts}
                        wardsOfDistrict={wardsOfDistrict}
                      />
                    )}
                    <CardAndBillForm
                      setIncludeBill={setIncludeBill}
                      includeBill={includeBill}
                      requestCard={requestCard}
                      setRequestCard={setRequestCard}
                      requestCardContent={requestCardContent}
                      setRequestCardContent={setRequestCardContent}
                      selectedDate={selectedDate}
                    />
                    <CustomerNote label={'Ghi chú khác cho đơn hàng'} />
                    <ReceiveDateForm
                      selectedHour={selectedHour}
                      setSelectedHour={setSelectedHour}
                      setSelectedDate={setSelectedDate}
                      validHours={validHours}
                      selectedDate={selectedDate}
                      setSelectedEndHour={setSelectedEndHour}
                      initialMinReceiveDate={minReceiveDate}
                      hasFastDelivery={hasFastDelivery}
                      rejectFastDelivery={rejectFastDelivery}
                      currentHour={currentHour}
                      availableFrom={getFarthestAvailableDate(orderItems)}
                      valentineCount={valentineCount}
                      setStreet={setStreet}
                    />
                    {mooncakeCount ? (
                      <PreOrderMooncake mooncakeCount={mooncakeCount} />
                    ) : null}
                    {!mooncakeCount ? (
                      <PrepareTimeNote
                        longestPrepareTime={longestPrepareTime}
                      />
                    ) : null}
                    <Divider />
                  </div>

                  {/* Phan thong tin don hang */}
                  <div tw="mx-2 md:(border rounded border-gray-400 p-4)">
                    <CheckoutHeading2>Thông tin đơn hàng</CheckoutHeading2>
                    <OrderItems />
                    <div tw="mt-4">
                      <FlexRow>
                        <TextLeft>Số lượng sản phẩm :</TextLeft>
                        <TextRight>{_.sumBy(orderItems, 'quantity')}</TextRight>
                      </FlexRow>
                      <FlexRow>
                        <TextLeft>Tổng tiền hàng:</TextLeft>
                        <TextRight>
                          {currencyFormatter.format(totalOriginalValue)}
                        </TextRight>
                      </FlexRow>
                      {/* giảm giá */}
                      <FlexRow>
                        <TextLeft>Giảm giá:</TextLeft>
                        <TextRight>
                          {currencyFormatter.format(totalDiscount)}
                        </TextRight>
                      </FlexRow>
                      {voucher && (
                        <FlexRow>
                          <TextLeft>Voucher :</TextLeft>
                          <TextRight>
                            {currencyFormatter.format(
                              getVoucherDiscout({
                                voucher,
                                totalOrder: totalOriginalValue,
                              })
                            )}
                          </TextRight>
                        </FlexRow>
                      )}
                      {/* tổng tiền đã trừ giảm giá và voucherr */}
                      <FlexRow>
                        <TextLeft>Thành tiền:</TextLeft>
                        <TextRight>
                          {currencyFormatter.format(totalWithoutShip)}
                        </TextRight>
                      </FlexRow>
                      <FlexRow>
                        <TextLeft>Phí ship tạm tính:</TextLeft>
                        <TextRight>
                          {currencyFormatter.format(shippingFee)}
                        </TextRight>
                      </FlexRow>
                      <FlexRow>
                        <TextLeft>Tổng đơn:</TextLeft>
                        <TextRight>
                          {currencyFormatter.format(
                            totalWithoutShip + shippingFee
                          )}
                        </TextRight>
                      </FlexRow>
                      <Divider />
                    </div>
                    <SelectVoucher />
                    <NoteShippingFee
                      selectedDate={selectedDate}
                      orderItems={orderItems}
                    />
                    <ModeOfPayment
                      mooncakeCount={mooncakeCount}
                      birthdayCakeCount={birthdayCakeCount}
                      selectedDate={selectedDate}
                    />
                    {isValidSpecialEventOrder === false ? (
                      <OrderPolicyEventNote
                        validOrderProps={validOrderPropsMap[cakeEventDayLabel]}
                      />
                    ) : null}
                    <SubmitButton
                      loading={loading}
                      validOrderPropsMap={validOrderPropsMap}
                      dayLabel={cakeEventDayLabel}
                      isValidSpecialEventOrder={isValidSpecialEventOrder}
                    />
                  </div>
                </div>
              </div>
            </form>
          ) : null}
        </FormProvider>
      </Layout>
    </TailwindLayout>
  )
}

const OrderCustomerForm = () => {
  const { register, formState } = useFormContext()

  const [customerName, setCustomerName] = useState(
    localStorage.customerName || ''
  )
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState(
    localStorage.customerPhoneNumber || ''
  )

  return (
    <div tw="flex flex-row space-x-2 sm:(flex-col space-y-4) sm:space-x-0">
      <div>
        <Input
          {...register('orderCustomerInfo')}
          type="text"
          placeholder="Họ và tên"
          onChange={(event) => {
            const value = event.target.value
            setCustomerName(value)
            localStorage.setItem('customerName', value)
          }}
          value={customerName}
        />
        <p tw="text-red-500">{formState.errors.orderCustomerInfo?.message}</p>
      </div>

      <div>
        <Input
          {...register('orderPhoneNumber')}
          type="text"
          placeholder="Số điện thoại"
          onChange={(event) => {
            const value = event.target.value.trim()
            setCustomerPhoneNumber(value)
            localStorage.setItem('customerPhoneNumber', value)
          }}
          value={customerPhoneNumber}
        />
        <p tw="text-red-500">{formState.errors.orderPhoneNumber?.message}</p>
      </div>
    </div>
  )
}

const ReceiverCustomerForm = () => {
  const { register, formState, watch, setValue } = useFormContext()
  const { orderCustomerInfo, orderPhoneNumber } = watch()
  const [sameOrderInfo, setSameInfo] = useState(false)
  useEffect(() => {
    if (sameOrderInfo) {
      setValue('receiverInfo', orderCustomerInfo)
      setValue('receiverPhoneNumber', orderPhoneNumber)
    }
  }, [sameOrderInfo, orderCustomerInfo, orderPhoneNumber, setValue])
  const handleSetValue = () => {
    setSameInfo(!sameOrderInfo)
  }

  useEffect(() => {
    if (
      formState.errors.orderCustomerInfo ||
      formState.errors.orderPhoneNumber ||
      formState.errors.receiverInfo ||
      formState.errors.receiverPhoneNumber ||
      formState.errors.selectedDistrict ||
      formState.errors.selectedWard ||
      formState.errors.street
    ) {
      const errorMessages = [
        formState.errors.orderCustomerInfo?.message,
        formState.errors.orderPhoneNumber?.message,
        formState.errors.receiverInfo?.message,
        formState.errors.receiverPhoneNumber?.message,
        formState.errors.selectedDistrict?.message,
        formState.errors.selectedWard?.message,
        formState.errors.street?.message,
      ]

      const filteredErrors = compact(
        uniq(errorMessages.filter((message) => message))
      )

      if (filteredErrors.length > 0) {
        forEach(filteredErrors, (errorMessage) => errorToast(errorMessage))
      }
    }
  }, [formState.errors])

  return (
    <div tw="flex flex-col gap-4">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}

      <CustomCheckBox
        handleSetValue={handleSetValue}
        checkValue={sameOrderInfo}
        checkContent={'Người nhận giống người đặt'}
      />
      <div tw="flex flex-row space-x-2 sm:(flex-col space-y-4) sm:space-x-0">
        <div>
          <Input
            {...register('receiverInfo')}
            type="text"
            css={[sameOrderInfo ? tw`bg-disable-grey-500` : tw`bg-white`]}
            placeholder="Họ và tên"
            disabled={sameOrderInfo ? true : false}
          />
          <p tw="text-red-500">{formState.errors.receiverInfo?.message}</p>
        </div>
        <div>
          <Input
            {...register('receiverPhoneNumber')}
            type="text"
            css={[sameOrderInfo ? tw`bg-disable-grey-500` : tw`bg-white`]}
            placeholder="Số điện thoại"
            disabled={sameOrderInfo ? true : false}
          />
          <p tw="text-red-500">
            {formState.errors.receiverPhoneNumber?.message}
          </p>
        </div>
      </div>
    </div>
  )
}

// NOTES
const PrepareTimeNote = ({ longestPrepareTime }) => {
  const prepareTimeDisplay = getPrepareTimeDisplay(longestPrepareTime)
  return (
    <>
      <p css={prepareTextStyle}>
        Savor cần ít nhất
        <strong> {prepareTimeDisplay} </strong>
        để chuẩn bị đơn hàng của bạn và sẽ giao trong thời gian khoảng
        <strong> 30 phút đến 1 tiếng</strong>.
      </p>
    </>
  )
}
const PreOrderMooncake = ({ mooncakeCount }) => {
  // const preInfos = getMooncakePreOrderDayNum({ mooncakeCount, forNote: true })
  return (
    <p css={prepareTextStyle}>
      {/* Thời gian trả hàng với đơn BTT (kể từ ngày đặt hàng):
      {preInfos.map((info, index) => (
        <li key={index}>
          Đơn {info.quantityNote} bánh: {info.day} ngày
        </li>
      ))} */}
      {/* Với đơn BTT, quý khách vui lòng đặt trước ít nhất 24h. */}
      {dayjs(today).isBefore(dayjs('2023-09-26')) && (
        <>
          <br />
          Hiện tại do số lượng đơn trả vào 2 ngày{' '}
          <strong>CN 24/09 và Thứ 2 25/09 đã quá tải</strong>, quý khách vui
          lòng nhận sang ngày khác giúp Savor nha ạ.
        </>
      )}
      {NEED_CSKH_CHECK ? (
        <span tw="text-red-400">
          Với đơn Bánh Trung Thu hiện tại, Savor sẽ liên hệ lại để xác nhận thời
          gian có thể giao, bạn <strong>vui lòng để ý điện thoại</strong> nha ạ!
        </span>
      ) : null}
    </p>
  )
}
const NoteShippingFee = ({ selectedDate, orderItems }) => {
  return (
    <div tw="mb-2">
      <CheckoutHeading2>Phí ship</CheckoutHeading2>
      <div css={CheckoutDescStyle}>
        {shipPolicy({ receivedDate: selectedDate, orderItems })}
      </div>
    </div>
  )
}

const OrderPolicyEventNote = ({ validOrderProps }) => {
  return (
    <div tw="mt-2" css={CheckoutDescStyle}>
      {validOrderProps.policyNote}
    </div>
  )
}

const SubmitButton = ({
  loading,
  validOrderPropsMap,
  dayLabel,
  isValidSpecialEventOrder,
}) => {
  const validOrderProps = validOrderPropsMap[dayLabel]
  return !isValidSpecialEventOrder && validOrderProps.submitAlt ? (
    validOrderProps.submitAlt
  ) : (
    <input
      type="submit"
      disabled={loading}
      css={SubmitButtonStyle}
      value={loading ? 'Đang xử lý ...' : 'Đặt hàng'}
    />
  )
}

const FlexRow = tw.div`flex mb-2`
const TextLeft = tw.div`flex-1 font-savor-cake-secondary-quicksand text-left font-bold text-base md:text-lg`
const TextRight = tw.div`flex-1 font-savor-cake-secondary-quicksand text-right font-bold text-base md:text-xl`

const prepareTextStyle = tw`text-sm md:text-base lg:text-lg mt-2 text-gray-600 font-savor-cake-secondary-quicksand font-medium`

const SubmitButtonStyle = tw`
  w-2/3 flex mx-auto text-center items-center justify-center mt-4 px-6 py-5
  bg-savor-cake-primary-green-avocado-0 text-white
  font-bold text-lg lg:text-xl leading-tight
  capitalize rounded-2xl
  hover:shadow-lg hover:-translate-y-1 focus:shadow-lg focus:outline-none focus:ring-0
  disabled:bg-gray-300 disabled:text-gray-500
  active:bg-blue-800 active:shadow-lg transition duration-300 ease-in-out cursor-pointer
`
const CustomCheckBox = ({
  handleSetValue,
  checkValue,
  checkContent,
  disabled = false,
}) => {
  const handleClick = () => {
    if (!disabled) {
      handleSetValue()
    }
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      onClick={handleClick}
      role="button"
      tabIndex="0"
      tw="flex flex-row items-center mb-2 w-max cursor-pointer"
      disabled={disabled}
    >
      <div
        css={[
          tw` mr-2 w-5 h-5 border border-savor-cake-primary-green-avocado-0 rounded flex justify-center items-center pl-1`,
          checkValue && tw`bg-savor-cake-primary-green-avocado-100`,
          disabled &&
            tw`cursor-not-allowed bg-disable-grey-500 border-savor-cakes-grey-600`,
        ]}
      >
        <Icon
          className="small"
          tw="text-white"
          name={checkValue ? 'check' : ''}
        />
      </div>
      <span tw="text-base md:text-lg font-savor-cake-secondary-quicksand font-medium">
        {checkContent}
      </span>
    </div>
  )
}

const validOrderPropsMap = {
  normal: {},
  '8t3': {
    invalidOrderError:
      'Đơn hàng có Sản phẩm KHÔNG nhận giao vào 2 ngày 7,8 tháng 3.',
    submitAlt: (
      <input
        type="button"
        onClick={() => {
          navigate('/83/')
        }}
        css={SubmitButtonStyle}
        tw="bg-20t10-pink-300 text-20t10-brown-500"
        value="Xem bánh 8/3"
      />
    ),
    policyNote: (
      <span tw="text-red-600">
        🎂 Savor chỉ nhận giao các mẫu bánh 8/3 + socola từ giờ đến hết ngày
        8/3, và không nhận mua kèm <strong>hộp mica</strong> ạ.
        <br /> - Quý khách vui lòng chuyển đến Trang 8/3 để xem thêm.
        <br />- Hoặc tham khảo mục Bánh kem 8/3 tại trang chủ savor.vn
      </span>
    ),
  },
  '20t10': {
    invalidOrderError:
      'Đơn hàng có Sản phẩm KHÔNG nhận giao từ giờ đến hết 20/10.',
    submitAlt: (
      <input
        type="button"
        onClick={() => {
          navigate('/')
        }}
        css={SubmitButtonStyle}
        tw="bg-20t10-pink-300 text-20t10-brown-500"
        value="Xem bánh 20/10"
      />
    ),
    policyNote: (
      <span tw="text-red-600">
        🎂 Savor chỉ nhận giao các mẫu bánh 20/10 từ giờ đến hết ngày 20. <br />
        - Vui lòng chuyển đến Trang 20/10 để xem thêm.
        <br />- Hoặc tham khảo mục Bánh kem 20/10 tại trang chủ savor.vn
      </span>
    ),
  },
}
