import React, { useMemo } from 'react'
import tw from 'twin.macro'
import { filter, find, get, keyBy, sumBy } from 'lodash'
import { currencyFormatter } from '../../utils/utils'
import { Divider } from 'semantic-ui-react'
import { getProductImageById, getProductImageBySku } from '../../utils/utils'
import { cakeItemVariants } from '../../utils/constants'
// import { getPrepareTimeDisplay } from '../../utils/receiveHours'
import { AddonsText, PannaTastes } from '../CartPage/CartItem'

const OrderItem = ({ orderItem }) => {
  const variantsObj = keyBy(orderItem.variants, 'type')
  const hasTextInBase =
    !!variantsObj[cakeItemVariants.TEXT_IN_BASE]?.description
  const hasTextInCake =
    !!variantsObj[cakeItemVariants.TEXT_IN_CAKE]?.description
  // const prepareTimeDisplay = useMemo(
  //   () => getPrepareTimeDisplay(orderItem.prepareTime),
  //   [orderItem.prepareTime]
  // )
  const addons = filter(orderItem.variants, 'sku')
  const customizedPannaCotta = find(orderItem.variants, { type: 'PANNA_COTTA' })
  return (
    <>
      <div tw="grid grid-cols-8 grid-rows-1">
        <div tw="col-start-1 col-end-3 row-start-1 row-span-2 mr-2">
          {getProductImageBySku(orderItem.sku) ||
            getProductImageById({ id: orderItem.productId })}
        </div>
        <div tw="col-start-3 col-end-7 row-start-1 row-end-2 text-sm font-bold md:text-base font-savor-cake-secondary-quicksand">
          {`${orderItem.description} ${orderItem.size ? orderItem.size : ''}`}
          {/* {orderItem.prepareTime &&
          (!isMooncake || (isMooncake && !NEED_CSKH_CHECK)) ? (
            <div tw="text-red-400 text-sm">
              Thời gian chuẩn bị: {prepareTimeDisplay}
            </div>
          ) : null} */}
          {/* tạm ẩn đi vì đang chênh lệch với longestPrepareTime khi update lại distance hoặc kitchen order condition */}
          <div tw="text-black text-sm md:text-base">
            SL: {orderItem.quantity}
          </div>
          {get(addons, 'length') ? <AddonsText addons={addons} /> : null}
          {customizedPannaCotta &&
          sumBy(customizedPannaCotta.options, 'quantity') ? (
            <>
              <div tw="text-savor-cake-secondary-blueberry-0 text-sm md:text-base">
                Các vị:
              </div>
              <PannaTastes
                tastes={filter(
                  customizedPannaCotta.options,
                  (option) => option.quantity > 0
                )}
              />
            </>
          ) : null}
          {hasTextInCake && (
            <div tw="mt-1">
              Chữ viết trên mặt bánh:{' '}
              <span tw="font-semibold text-savor-cake-secondary-red-strawberry-0">
                {variantsObj[cakeItemVariants.TEXT_IN_CAKE]?.description}
              </span>
            </div>
          )}
          {hasTextInBase && (
            <div tw="mt-1">
              Chữ viết trên đế:{' '}
              <span tw="font-semibold text-savor-cake-secondary-red-strawberry-0">
                {variantsObj[cakeItemVariants.TEXT_IN_BASE]?.description}
              </span>
            </div>
          )}
        </div>
        <div tw="col-start-7 col-end-9 row-start-1 row-span-2 flex justify-start items-center flex-col">
          {orderItem.originalPrice &&
          orderItem.originalPrice !== orderItem.soldPrice ? (
            <NormalPrice>
              {currencyFormatter.format(orderItem.originalPrice)}
            </NormalPrice>
          ) : null}
          <SalePrice>{currencyFormatter.format(orderItem.soldPrice)}</SalePrice>
        </div>
      </div>
      <Divider />
    </>
  )
}

export default OrderItem

const NormalPrice = tw.div`text-sm md:text-base text-gray-300 line-through font-semibold font-savor-cake-secondary-quicksand`
const SalePrice = tw.div`text-sm md:text-base text-black font-bold font-savor-cake-secondary-quicksand`
