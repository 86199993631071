import * as yup from 'yup'
import { validateFullName } from './utils'

const fullNameWarn = 'Vui lòng nhập tên'
const invalidFullName = 'Vui lòng điền đủ họ và tên (gồm 2 từ trở lên)'
const phoneInputWarn = 'Vui lòng nhập số điện thoại'
const invalidPhone = 'Số điện thoại không đủ 10 số'
export const onlineOrderValidationSchema = yup.object().shape({
  orderCustomerInfo: yup
    .string()
    .required(`${fullNameWarn} người đặt`)
    .test('fullName', invalidFullName, validateFullName),
  orderPhoneNumber: yup
    .string()
    .required(`${phoneInputWarn} người đặt`)
    .matches(/^[0-9]{10}$/, invalidPhone),
  receiverInfo: yup
    .string()
    .required(`${fullNameWarn} người nhận`)
    .test('fullName', invalidFullName, validateFullName),
  receiverPhoneNumber: yup
    .string()
    .required(`${phoneInputWarn} người nhận`)
    .matches(/^[0-9]{10}$/, invalidPhone),
  selectedDistrict: yup
    .string()
    .test('selectedDistrict', 'Vui lòng chọn quận', () => {
      const selectedDistrict = localStorage.getItem('selectedDistrict')
      if (JSON.parse(selectedDistrict)) {
        return true
      }
      return false
    }),
  selectedWard: yup
    .string()
    .test('selectedWard', 'Vui lòng chọn phường', () => {
      const selectedWard = localStorage.getItem('selectedWard')
      if (JSON.parse(selectedWard)) {
        return true
      }
      return false
    }),
  street: yup.string().required('Vui lòng nhập địa chỉ cụ thể'),
})
