import { useAtomValue, useAtom } from 'jotai'
import { selectedDateAtom } from '../states/selectedDate'
import { checkInKitchenTimeRange, defaultOffB7 } from '../utils/ordersTime'
import {
  directRejectKitchensAtom,
  extendKitchensAtom,
} from '../states/selectedAddress'
import { useEffect, useState } from 'react'
import { forEach, get, keys } from 'lodash'
import { getAdjustedCurrentHour } from '../utils/receiveHours'
import { kitchenConditionsMapAtom } from '../states/kitchenCondition.state'

export default function useOrderCondition() {
  const selectedDate = useAtomValue(selectedDateAtom)
  const kitchenConditionsMap = useAtomValue(kitchenConditionsMapAtom)
  const [, setDirectRejectKitchens] = useAtom(directRejectKitchensAtom)
  const [, setExtendKitchens] = useAtom(extendKitchensAtom)

  const [currentHour, setCurrentHour] = useState(getAdjustedCurrentHour())

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentHour(getAdjustedCurrentHour())
    }, 60000)

    return () => clearInterval(intervalId)
  }, [])

  // Dùng set list bếp tắt ngừng đặt hản
  // Đang dùng để ko chọn nhận trực tiếp dược vì phải ship từ bếp khác sang. Còn ko chọn trực tiếp thì cũng đang chặn ở server rồi
  useEffect(() => {
    const kitchenCodes = keys(kitchenConditionsMap)
    let directRejectKitchens = []
    let extendKitchens = []

    forEach(kitchenCodes, (kitchenCode) => {
      const kitchenCondition = get(
        kitchenConditionsMap,
        `${kitchenCode}.savorWebOrder`,
        {}
      )
      // Nếu disallow hẳn luôn
      if (
        checkInKitchenTimeRange({ kitchenCondition, selectedDate }) &&
        kitchenCondition.disallowOrder
      ) {
        directRejectKitchens.push(kitchenCode)
      }
      // Nếu là B7
      if (defaultOffB7({ code: kitchenCode, selectedDate, currentHour })) {
        if (
          checkInKitchenTimeRange({
            kitchenCondition,
            selectedDate,
          }) &&
          get(kitchenCondition, 'extendNormalShift')
        ) {
          extendKitchens.push(kitchenCode)
        } else {
          directRejectKitchens.push(kitchenCode)
        }
      }
    })
    setDirectRejectKitchens(directRejectKitchens)
    setExtendKitchens(extendKitchens)
  }, [
    kitchenConditionsMap,
    selectedDate,
    currentHour,
    setDirectRejectKitchens,
    setExtendKitchens,
  ])

  return {}
}
