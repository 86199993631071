import React, { useState, useMemo } from 'react'
import tw from 'twin.macro'
import { StaticImage } from 'gatsby-plugin-image'
import { useFormContext } from 'react-hook-form'

import { CheckoutHeading2, CheckoutDescStyle } from '../Base'
import { inPeak20t10, inPeak8t3 } from '../../utils/eventDays'

const creditIcon = (
  <StaticImage
    src="../../assets/svg/checkout-page/credit.svg"
    alt="CreditIcon"
    height={30}
  />
)
const cashIcon = (
  <StaticImage
    src="../../assets/svg/checkout-page/cash.svg"
    alt="CashIcon"
    height={40}
  />
)
const ModeOfPayment = ({ mooncakeCount, birthdayCakeCount, selectedDate }) => {
  const { register } = useFormContext()
  const [, setCOD] = useState(false)
  const hideCod = useMemo(
    () =>
      // (mooncakeCount &&
      //   dayjs().isAfter(mooncakeDay.subtract(15, 'day')) &&
      //   finalValue < 1000000) ||
      inPeak20t10({ selectedDate }) || inPeak8t3({ selectedDate }),
    [selectedDate]
  )
  return (
    <div>
      <CheckoutHeading2>Phương thức thanh toán</CheckoutHeading2>
      <div tw="font-savor-cake-secondary-quicksand">
        <div tw="flex items-center">
          <RadioBox
            defaultChecked
            id="transfer"
            onClick={() => {
              setCOD(false)
            }}
            type="radio"
            name="mode-of-payment"
            value="transfer"
            {...register('paymentMethod')}
          />

          <label
            htmlFor="transfer"
            css={CheckoutDescStyle}
            tw="ml-2 flex justify-start items-center space-x-2 w-full"
          >
            <span>Chuyển khoản ngân hàng</span>
            <span>{creditIcon}</span>
          </label>
        </div>
        {hideCod ? null : (
          <div tw="flex place-items-center">
            <RadioBox
              id="cod"
              onClick={() => {
                setCOD(true)
              }}
              type="radio"
              name="mode-of-payment"
              value="cod"
              {...register('paymentMethod')}
            />
            <label
              htmlFor="cod"
              css={CheckoutDescStyle}
              tw="ml-2 flex justify-start items-center space-x-2 w-full"
            >
              <span>Thanh toán khi nhận hàng (COD)</span>
              <span>{cashIcon}</span>
            </label>
          </div>
        )}
      </div>
      <p css={CheckoutDescStyle}>
        Savor khuyến khích quý khách chuyển khoản trước toàn bộ để được tự động
        xác nhận đơn hàng nhanh hơn ạ.
      </p>
      {hideCod ? (
        <p css={CheckoutDescStyle} tw="text-red-600">
          🎂 Đơn giao vào 2 ngày{' '}
          {inPeak8t3({ selectedDate }) ? '7 và 8/3' : '19/10 và 20/10'} cần
          thanh toán trước chuyển khoản 100%
        </p>
      ) : null}
    </div>
  )
}

export default ModeOfPayment

const RadioBox = tw.input`w-4 h-4 text-savor-cake-primary-green-avocado-0 bg-gray-100 border-gray-300 focus:ring-savor-cakes-green-800  focus:ring-2`
// const NoteCOD = ({ isCOD, mooncakeCount, birthdayCakeCount }) => {
//   return (
//     isCOD && (
//       <div tw="my-1 text-base pl-0 text-savor-cake-secondary-red-strawberry-0">
//         {birthdayCakeCount ? (
//           <p>
//             <strong tw="">
//               Với bánh sinh nhật (ngoại trừ size 13x6cm, 13x7cm, 14x6cm, 14x4cm){' '}
//             </strong>
//             thì sẽ <strong></strong>cần cọc ít nhất 50,000₫
//           </p>
//         ) : null}
//         {mooncakeCount ? (
//           <p>
//             Với đơn từ 1 triệu <strong>cần cọc</strong> 50% đơn hàng
//           </p>
//         ) : null}
//         <strong tw="text-base">
//           Sau khi đặt hàng, Savor sẽ liên hệ lại để hướng dẫn thanh toán ạ
//         </strong>
//       </div>
//     )
//   )
// }
