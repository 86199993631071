import React, { useLayoutEffect, useState, useMemo } from 'react'
import { Form } from 'semantic-ui-react'
import { useAtom } from 'jotai'
import { selectedVoucherAtom } from '../../states/selectedVoucher'
import { infoToast, successToast, errorToast } from '../../utils/toastify'
import { compact, get, includes, map, intersection, uniq } from 'lodash'
import { orderItemsAtom } from '../../states/selectedProduct'
import { getCakeSizeFromText } from './../../utils/utils'
import { birthdayCakeSkus } from './../../utils/products'
import dayjs from 'dayjs'

const Voucher = () => {
  const [couponCode, setCouponCode] = useState('')
  const [voucher, setVoucher] = useAtom(selectedVoucherAtom)
  const [orderItems] = useAtom(orderItemsAtom)
  const orderSizeNames = useMemo(
    () => compact(uniq(map(orderItems, 'sizeName'))),
    [orderItems]
  )
  const orderSkus = useMemo(
    () => compact(uniq(map(orderItems, 'sku'))),
    [orderItems]
  )

  useLayoutEffect(() => {
    setVoucher(null)
  }, [setVoucher])

  const handleChange = (e) => {
    setCouponCode(e.target.value)
  }

  const handleRemoveVoucher = () => {
    setVoucher(null)
    setCouponCode('')
    infoToast('Đã bỏ sử dụng voucher')
  }

  const handleOnClick = async (e) => {
    e.preventDefault()
    if (!couponCode) return errorToast('Vui lòng nhập mã giảm giá')
    const voucher = await fetchVoucher(couponCode)
    if (!voucher) return errorToast('Mã giảm giá sai hoặc đã được sử dụng')
    const voucherType = get(voucher, 'voucherType', '')
    const now = dayjs()
    const expiryDate = dayjs(get(voucher, 'expiryDate', ''))

    if (expiryDate.diff(now) < 0)
      return errorToast('Voucher đã quá hạn sử dụng')

    if (
      voucherType === 'DISCOUNT_CAKE_ORDER_NORMAL' &&
      !includes(orderSizeNames, 'nhỏ')
    ) {
      return errorToast(
        `Voucher này chỉ áp dụng với bánh cỡ nhỏ ${getCakeSizeFromText('nhỏ')}`
      )
    }
    if (
      voucherType === 'DISCOUNT_CAKE_ORDER_BIG' &&
      !includes(orderSizeNames, 'vừa')
    ) {
      return errorToast(
        `Voucher này chỉ áp dụng với bánh cỡ vừa ${getCakeSizeFromText('vừa')}`
      )
    }
    if (
      ['DISCOUNT_CAKE_ORDER_10_PERCENT', 'DISCOUNT_CAKE_ORDER'].includes(
        voucherType
      ) &&
      !intersection(orderSkus, birthdayCakeSkus).length
    ) {
      return errorToast('Voucher này chỉ áp dụng với bánh sinh nhật')
    }
    setVoucher(voucher)
    successToast('Đã áp dụng voucher')
  }

  async function fetchVoucher(couponCode) {
    try {
      const data = await fetch(
        `${process.env.GATSBY_API_URL}/vouchers/search?couponCode=${couponCode}`
      )
      const voucher = await data.json()
      return voucher
    } catch (error) {
      alert(`lỗi kết nối server ${error.message}`)
    }
  }

  return (
    <Form.Group>
      <Form.Input
        placeholder="Nhập mã giảm giá"
        name="coupon code"
        value={couponCode}
        onChange={handleChange}
        size="mini"
      />
      <Form.Button
        content="Sử dụng"
        size="tiny"
        onClick={handleOnClick}
        style={{ backgroundColor: '#61985c', color: '#fff' }}
      />
      {voucher && (
        <Form.Button
          content="Bỏ"
          type="button"
          size="tiny"
          basic
          color="red"
          onClick={handleRemoveVoucher}
        />
      )}
    </Form.Group>
  )
}

export default Voucher
